import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledFounders = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledFoundersImage = styled.img`
  width: 100%;
  max-width: ${Rem(582)};
  margin-bottom: ${Rem(46)};
`;

export const StyledFoundersContent = styled.div`
  @media (${BP.ipad}) {
    padding-left: ${Rem(128)};
  }
`;

export const StyledFoundersParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(12)};
`;

export const StyledFoundersCTA = styled.div`
  display: inline-block;
`;
